<template>
  <div>
    <HeaderUserInfo/>
    <div class="login-bg">
      <div class="lg-show">
        <img src="../assets/login_logo.png" height="344" width="643"/>
      </div>
      <div class="login-box pb-3">
        <div class="login-title p-1">登录账号</div>
        <div class="px-2">
          <div class="p-4 " style="border-bottom: 1px solid #c7c8cd">
            <div class="nav align-items-center my-2 login-item">
              <div class="login-ico-box"><img src="../assets/user_ico.png" height="24" width="24"/></div>
              <input type="text" v-model="username" placeholder="请输入您的账号">
            </div>
            <div class="nav align-items-center my-2 login-item">
              <div class="login-ico-box"><img src="../assets/passwd_ico.png" height="24" width="24"/></div>
              <input type="password" v-model="password" placeholder="请输入您的密码">
            </div>
          </div>
        </div>
        <div class="px-2">
          <div class="px-4">
            <div class="flex justify-content-center align-items-center back-color text-white mt-3 py-1"
                 style="border-radius: 3px" @click="login">登录
            </div>
          </div>

        </div>
      </div>
    </div>
    <FooterUs/>
  </div>
</template>

<script>
import FooterUs from "@/components/FooterUs";
import HeaderUserInfo from "@/components/HeaderUserInfo";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      checked: true,
      focusId: "",
    }
  },
  components: {FooterUs, HeaderUserInfo},
  methods: {
    login() {
      let that = this;
      if (this.username.length === 0) {
        this.$message.error('用户名不能为空哦');
      } else if (this.password.length === 0) {
        this.$message.error('密码不能为空哦');
      } else {
        var loginParams = {
          account: this.username,
          password: this.password
        }
        // 登录请求
        this.httpApi.login(loginParams).then(response => {
          console.log("data", response);
          if (response.code === 1) {
            localStorage.setItem("login", JSON.stringify(loginParams))
            // 将token存储到本地
            that.$store.commit('setUser', response.data.userinfo)
            //跳转到首页
            this.$message({
              message: "登录成功",
              type: "success"
            });
            that.$router.push('/home');
          }
        })
      }

    }
  }

}
</script>

<style scoped>
@media screen and (min-width: 700px) {
  .lg-show {
    display: block;
  }

  .nav-item-lg {
    display: flex;
  }
}
@media screen and (max-width: 700px) {
  .lg-show {
    display: none;
  }
}
.login-item {
  background-color: #bedbd6;
}

.login-item input {
  padding: 6px 12px;
  border: 0;
  outline: none;
}

.login-item input:active {
  border: 0;
}

.login-ico-box {
  padding: 6px 10px;
}

.login-box {
  background-color: #e9e9f3;
  box-shadow: 5px 5px 6px 6px rgba(0,0,0,.2);
}

.login-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  color: #FFF;
  background-color: #1cbc9a;
}

.login-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0;
  background: no-repeat url("~@/assets/login_bg.jpg");
  background-size: cover;
}
</style>