import VueRouter from 'vue-router'
import Index from '@/components/Index'
import Login from '@/components/Login'
import Home from '@/components/pages/Home'
import Vue from 'vue'
import Curriculum from "@/components/pages/Curriculum";
import Data from "@/components/pages/Data";
import Video from "@/components/pages/Video";
import News from "@/components/pages/News";
import Form from "@/components/pages/Form";
import Pdf from "@/components/pages/Pdf";
import NewsList from "@/components/pages/NewsList";
import File from "@/components/pages/File";

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {
            path: "/",
            name: 'Index',
            component: Index,
            redirect: "home",
            children: [
                {
                    path: "home",
                    name: "Home",
                    component: Home,
                },
                {
                    path: "curriculum",
                    name: "Curriculum",
                    component: Curriculum,
                    meta: {
                        keepAlive: true
                    }
                },
                {
                    path: "data",
                    name: "Data",
                    component: Data,
                    meta: {
                        keepAlive: true
                    }

                },
                {
                    path: "files",
                    name: "Files",
                    component: File,
                    meta: {
                        keepAlive: true
                    }
                },
                {
                    path: "form",
                    name: "Form",
                    component: Form,
                },
                {
                    path: "video/:id",
                    name: "Video",
                    props: true,
                    component: Video,
                    meta: {
                        keepAlive: true
                    }
                },
                {
                    path: "pdf/:id",
                    name: "Pdy",
                    props: true,
                    component: Pdf,
                    meta: {
                        keepAlive: true
                    }
                },
                {
                    path: "News/:id",
                    name: "News",
                    props: true,
                    component: News,
                },
                {
                    path: "News",
                    name: "News",
                    component: NewsList,
                }
            ]
        },
        {
            path: "/login",
            name: 'Login',
            component: Login,
        }

    ]
})

export default router