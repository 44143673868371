// 查找货物已占用位置

import request from "@/api";


const httpApi = {
    login(params) {
        return request({
            url: "/api/user/login", method: 'post', data: params
        });
    },
    getCurriculum(params) {
        return request({
            url: "/api/kkl/kc/infos", method: 'post', params: params
        });
    },
    getCurriculumList(params) {
        return request({
            url: "/api/index/getClassInfo", method: 'post', data: params
        });
    },
    getFileList(params) {
        return request({
            url: "/api/index/downlist", method: 'post', data: params
        });
    },
    getNews(params) {
        return request({
            url: "/api/news/index", method: 'post', params: params
        });
    },
    getNewsInfo(params) {
        return request({
            url: "/api/news/info", method: 'post', data: params
        });
    },
    sendForm(params) {
        return request({
            url: "/api/index/setEnterForm", method: 'post', data: params
        });
    },
    getInfo(params) {
        return request({
            url: "/api/index/getkcinfo", method: 'post', data: params
        });
    },
    getConfig() {
        return request({
            url: "/api/index/getGsjs", method: 'post',data:{}
        });
    }
};


export default httpApi
