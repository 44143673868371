<template>
  <div class="flex justify-content-center"
       style="  background-color: #1cbc9a;  box-shadow: inset 0px -2px 5px #41a791;">
    <div class="nav-box text-white w-100">
      <router-link to="/home" class="nav-item" :class="{'active':$store.getters.nav === 0}">
        <div class="nav-item-lg">
          <div>首  页</div>
<!--          <div class="lg-show">-->
<!--            /-->
<!--          </div>-->
<!--          <div>HOME PAGES</div>-->
        </div>
      </router-link>
      <router-link to="/curriculum" class="nav-item" :class="{'active':$store.getters.nav === 1}">
        <div class="nav-item-lg">
          <div>
            课程中心
          </div>
<!--          <div class="lg-show">-->
<!--            /-->
<!--          </div>-->
<!--          <div>-->
<!--            CURRICULUM-->
<!--          </div>-->
        </div>
      </router-link>
<!--      <router-link to="/data" class="nav-item" :class="{'active':$store.getters.nav === 2}">-->
<!--        <div class="nav-item-lg">-->
<!--          <div>真题习题</div>-->
<!--&lt;!&ndash;          <div class="lg-show">&ndash;&gt;-->
<!--&lt;!&ndash;            /&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--&lt;!&ndash;          <div>&ndash;&gt;-->
<!--&lt;!&ndash;            TIME INFO&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--      </router-link>-->
      <router-link to="/files" class="nav-item" :class="{'active':$store.getters.nav === 3}">
        <div class="nav-item-lg">
          <div>讲义及习题下载</div>
<!--          <div class="lg-show">-->
<!--            /-->
<!--          </div>-->
<!--          <div>-->
<!--            NEWS INFO-->
<!--          </div>-->
        </div>
      </router-link>
    </div>
  </div>

</template>
<script>
export default {
  name: 'HeaderNav'
}
</script>
<style scoped>
@media screen and (min-width: 700px) {
  .lg-show {
    display: block;
  }

  .nav-item-lg {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .lg-show {
    display: none;
  }
}

a {
  text-decoration: none;
}

.active {
  box-shadow: inset 0 4px 5px -5px rgba(0, 0, 0, .5);
  background-color: #fff;
  color: #1cbc9a !important;
}

.nav-box {

  max-width: 1140px;
  height: 60px;

  display: flex;
  align-items: stretch;
}

.nav-item {
  color: #FFF;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item:not(:first-child) {
  justify-content: space-between;
}

.nav-item:not(:first-child):after {
  content: " ";
  height: 50px;
  border-right: 1px rgba(0,0,0,0) solid;
}

.nav-item:not(:first-child):before {
  content: "";
  height: 50px;
  border-right: 1px #fff solid;
}
</style>