<template>
  <div class="nav justify-content-center align-items-center py-3" style="background-color: #f5f5f5">
    <div class="box nav flex-column align-items-center">
      <div class="tips w-100">  <router-link tag="span" :to="'/data'" >全部课程</router-link>  > {{info.unit_name}}</div>
      <div class="w-100 vh-100 mt-3" v-if="!is_app">
        <iframe class="w-100 h-100" :src="info.infox" frameborder="0"></iframe>
      </div>
      <div v-else>
        <div class="w-100" v-for="(item,index) in info.png" :key="index">
          <img class="w-100" :src="item" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Video",
  data() {
    return {
      is_app:false,
      info:{}
    }
  },
  props:['id'],
  mounted() {
    this.is_app = this._isMobile()
    this.$store.commit('changeNav', 1)
    this.getInfo()
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getInfo(){
      let that = this
      this.httpApi.getInfo({id:this.id}).then(response =>{
        console.log(response);
        that.info = response.data
      })
    }
  }
}
</script>

<style scoped>
.box {
  background-color: #fff;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, .6);
  max-width: 1140px;
  width: 100%;
  padding: 12px 20px;
}

.tips {
  color: #616161;
  border-bottom: #d1d1d1 1px solid;
}
</style>