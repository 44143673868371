import axios from 'axios'
import store from '../store'
import {Message, Loading} from 'element-ui';
import router from '@/router'
import BaseConfig from "@/config";

let loadingInstance;
axios.defaults.baseURL = process.env.VUE_APP_NAME_URL;
let request = axios.create({
    timeout: 2500,
    headers: {
        "Content-Type": "application/json",
    }
});

// 请求拦截
request.interceptors.request.use(
    config => {
        console.log(config);
        if (BaseConfig.loading) {
            loadingInstance = Loading.service({
                lock: true,
                background: 'rgba(0, 0, 0, 0.7)'
            });
        }

        //加入token
        if (config.url !== "/api/user/login" && store.state.user.token) {
            config.headers.token = store.state.user.token ?? "";
        }
        return config;
    },
    error => {
        clearLoading();
        Message.success("失败")
        return Promise.reject(error)
    }
)

function clearLoading() {
    setTimeout(() => {
        loadingInstance.close()
    }, 500);
}

request.interceptors.response.use(
    response => {  //成功请求到数据
        console.log(response);
        if (BaseConfig.loading) {
            clearLoading();
        }
        switch (response.data.code) {
            case 0 :
                Message({
                    message: response.data.msg,
                    type: 'error'
                });
                return response.data;
            case 401:
            case 403:
                Message({
                    message: response.data.message,
                    type: 'error'
                });
                router.replace({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                });
                break;
            default:
                return response.data;
        }
    },
    error => {
        if (BaseConfig.loading) {
            clearLoading();
        }
        if (axios.isCancel(error)) {
            console.log('中断请求', error.message);
        }
        console.log('中断请求', error.message);
        if (error.response) {
            switch (error.response.status ?? 0) {
                case 401:
                    Message({
                        message: '请先登录',
                        type: 'error'
                    });
                    store.commit("logout")
                    break;
                default:
                    Message({
                        message: '请求失败:' + error.message,
                        type: 'error'
                    });
            }
        }


        return {result: 0};
    },
);


export default request
