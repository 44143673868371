<template>
  <div>
    <div class="w-100 back-color nav justify-content-center">
      <div class="flex flex-wrap justify-content-between align-items-center p-3 w-100 text-white"
           style="min-height: 225px;max-width: 1040px">
        <div class="" style="">
          <div>公司地址:</div>
          <div>
            <p v-if="baseData" style="white-space: pre-line;">
              {{ baseData.gsdz }}
            </p>

          </div>
        </div>
        <div class="flex flex-column align-items-center">
          <div style="width: 135px;height: 135px;background-color: #FFF">
            <img :src="baseData.ios" height="100%" width="100%"/>
          </div>
          <div>IOS系统下载</div>
        </div>
        <div class="flex flex-column align-items-center" v-if="baseData.app">
          <div style="width: 135px;height: 135px;background-color: #FFF">
            <img :src="baseUrl + baseData.app" height="100%" width="100%"/>
          </div>
          <div>安卓系统下载</div>
        </div>
        <!--        <div class="">-->
        <!--          <div>联系电话:</div>-->
        <!--          <div>0631-312-3132</div>-->
        <!--          <div>0631-312-3132</div>-->
        <!--        </div>-->
      </div>
    </div>

    <div style="color: #616161;text-align: center;padding: 10px 0">
      Copyright 2018-2022 鲁班培训 All Rights Reserved. <a href="#">鲁ICP备20002174号-1</a> <a
        href="/c.jpg">营业执照</a>
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'FooterUs',
  computed: {
    ...mapState(['baseData']),
    baseUrl: () => {
      return process.env.VUE_APP_NAME_URL
    }
  },
}
</script>
