<template>
  <div class="w-100 flex justify-content-center px-2 py-2">
    <div class="flex align-items-center justify-content-between w-100" style="height: 55px;max-width: 1230px">
      <div>
        <router-link to="/home"><img src="./../assets/logo.png" alt="" style="height: 50px"></router-link>
      </div>
      <div class="nav align-items-center">
        <router-link tag="div" to="/login" v-if="!isLogin">
          <button type="button" class="btn btn-link login-btn">立即登录</button>
        </router-link>
        <div class="nav align-items-center" v-else>
          <div>
            欢迎您,用户{{ this.$store.state.user.nickname }}
          </div>
          <div class="avatar mx-3" :style="{'background-image':'url('+this.$store.state.user.avatar+')'}"></div>
          <div class="me-3" style="height: 20px;width: 1px;background-color: #a9a9a9"></div>
          <div class="text-black" @click="logout">
            退出
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'HeaderUserInfo',
  data() {
    return {
    }
  },
  computed: mapState(['isLogin']),
  methods:{
    logout(){
      this.$store.commit("logout")
      this.$router.push("/login")
    }
  },
  mounted() {

  },
  props: {}
}
</script>
<style scoped>
.avatar {
  border-radius: 50%;
  background-size: cover;
  overflow: hidden;
  width: 2rem;
  height: 2rem;
}

.login-btn {
  /*background-color: #2a78bd;*/
  /*box-shadow: 0 0 6px 6px #EEE;*/
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  background-color: #1cbc9a;
  box-shadow: 0 5px 6px 0px rgba(0,0,0,.2);

}
</style>

