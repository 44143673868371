<template>
  <div>
    <div><img src="~@/assets/bg.jpg" alt="" width="100%" height="auto"></div>
<!--    <div class="flex justify-content-center w-100 select-box" style="">-->
<!--      <div class="flex align-items-center text-white" style=" width:876px;height: 100px; background-color: #1cbc9a;">-->
<!--        <router-link tag="div" to="/form" class="flex-grow-1 flex flex-column justify-content-center align-items-center">-->
<!--          <img src="@/assets/ico1.png" alt="">-->
<!--          报名咨询-->
<!--        </router-link>-->
<!--        <div style="height: 60px;width: 1px;background-color: #FFF"></div>-->
<!--        <router-link tag="div" to="/home"  class="flex-grow-1 flex flex-column justify-content-center align-items-center">-->
<!--          <img src="@/assets/ico2.png" alt="">-->
<!--          新闻资讯-->
<!--        </router-link>-->
<!--      </div>-->
<!--    </div>-->
    <div class="flex justify-content-center w-100" style="background-color: #f5f5f5">
      <div class="w-100" style="max-width: 1080px;background-color: #FFF;padding: 80px 30px">
        <div class="w-100 flex justify-content-between">
          <table class="w-100">
            <tr class="border-bottom new-item" style="height: 40px" v-for="(item,index) in list" :key="index"
                @click="viewNews(item.id)">
              <td width="100" class="new-type">新闻</td>
              <td class="new-title">{{ item.title }}</td>
              <td width="100" class="new-date">{{ dateStr(item.createtime) }}</td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsList",
  data() {
    return {
      list: []
    }
  },
  mounted() {
    this.$store.commit('changeNav', 3)
    this.getNews()
  },
  methods: {
    dateStr(date) {
      let time = new Date(date * 1000)
      return this.format(time, "yyyy-MM-dd")
    },
    viewNews(id) {
      this.$router.push("news/" + id)
    },
    getNews() {
      let that = this;
      this.httpApi.getNews().then(response => {
        console.log(response);
        that.list = response.data.rows
      })
    }
  }
}
</script>
<style scoped>
@media screen and (min-width	: 700px){
  .select-box{
    position: absolute;
    margin-top: -50px;
  }
}

.new-type {
  color: #1cbc9a;
  text-decoration: underline;
}

.new-title {
  font-weight: bold;
}

.new-date {
  color: #adadad;
}

.new-item {
  cursor: pointer;
}

.new-item:hover {
  color: #1cbc9a;
  cursor: pointer;
}
</style>