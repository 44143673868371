<template>
  <div>
    <div><img src="~@/assets/bg.jpg" alt="" width="100%" height="auto"></div>
    <div class="flex justify-content-center w-100 select-box" style="background-color: #f5f5f5">
      <div class="flex align-items-center text-white" style=" width:876px;height: 100px; background-color: #1cbc9a;">
        <router-link tag="div" to="/form"
                     class="flex-grow-1 flex flex-column justify-content-center align-items-center">
          <img src="@/assets/ico1.png" alt="">
          报名咨询
        </router-link>
        <div style="height: 60px;width: 1px;background-color: #FFF"></div>
        <router-link tag="div" to="/home"
                     class="flex-grow-1 flex flex-column justify-content-center align-items-center">
          <img src="@/assets/ico2.png" alt="">
          新闻资讯
        </router-link>
      </div>
    </div>
    <div class="flex justify-content-center w-100" style="background-color: #f5f5f5">
      <div class="w-100 bg-white box px-5" style="max-width: 876px">
        <div class="title p-2">报名表</div>
        <div class="nav flex-column align-items-center justify-content-around p-3">
          <input class="w-100" type="text" placeholder="请输入您的姓名 *" v-model="params.name">
          <input class="w-100" type="text" placeholder="请输入您的手机或电话号码 *" v-model="params.phone">
          <textarea class="w-100" placeholder="备注" v-model="params.info">

          </textarea>
          <div class="send-btn" @click="send">
            提 交
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      params:{
        name:"",
        phone:"",
        info:""
      }
    }
  },
  mounted() {
    this.$store.commit('changeNav', 0)
  },
  methods:{
    send(){
      this.httpApi.sendForm(this.params).then(response=>{
        console.log(response);
        this.$message.success("提交成功")
      })
    }
  }
}
</script>

<style scoped>
.box {
  margin: 2rem 0;
  background-color: #fff;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, .6);
  max-width: 1140px;
  width: 100%;
  padding: 12px 20px;
}
@media screen and (min-width	: 700px){
  .select-box{
    position: absolute;
    margin-top: -50px;
  }
}

input {
  border: 1px #d1d1d1 solid;
  padding: 6px;
  margin: 6px 0;
}

textarea {
  border: 1px #d1d1d1 solid;
  padding: 6px;
  margin: 6px 0;
}

.title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #515151;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px #515151 solid;
}

.send-btn {
  cursor: pointer;
  margin-top: 6px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  background-color: #1cbc9a;
  width: 100%;
  padding: 6px;
}
</style>