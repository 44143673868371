<template>
  <div class="nav justify-content-center align-items-center py-3" style="background-color: #f5f5f5">
    <div class="box nav flex-column align-items-center">
      <div class="tips w-100">
        <router-link tag="span" :to="'/curriculum'">全部课程</router-link>
        > {{ info.unit_name }}
      </div>
      <div class="w-100 my-4 video-box" :class="{v_float:v_float}" ref="video_box">
        <video controls ref="player" @contextmenu.prevent="stopMenu" :src="info.info" class="w-100" controlslist="nodownload noremoteplayback" disablePictureInPicture></video>
      </div>
      <div class="w-100 vh-100" v-if="!is_app">
        <embed class="w-100 h-100" :src="info.infox" frameborder="0">
      </div>
      <div v-else>
        <div class="w-100" v-for="(item,index) in info.png" :key="index">
          <img class="w-100" :src="item" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Pdfh5 from "pdfh5";
export default {
  name: "Video",
  data() {
    return {
      is_app: true,
      // pdfh5: null,
      info: {},
      v_float: false,
    }
  },
  props: ['id'],
  mounted() {

    window.addEventListener('scroll', this.handleScroll)

    this.is_app = this._isMobile()
    this.$store.commit('changeNav', 1)
    this.getInfo()

  },
  methods: {
    stopMenu(){
      return false
    },
    handleScroll() {
      //获取位置

      this.v_float = document.documentElement.scrollTop >= 158

    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    getInfo() {
      let that = this
      this.httpApi.getInfo({id: this.id}).then(response => {
        console.log(response);
        that.info = response.data
        // that.pdfh5 = new Pdfh5("#ppdf", {
        //   pdfurl: response.data.infox
        // });
        // //监听完成事件
        // that.pdfh5.on("complete", function (status, msg, time) {
        //   console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        // })
      })
    }
  }
}
</script>

<style scoped>
.video-box {
  position: relative;
}

@media screen and (max-width: 700px) {
  .v_float {
    position: fixed;
    top: 0;
    width: 90% !important;
  }
}


.box {
  background-color: #fff;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, .6);
  max-width: 1140px;
  width: 100%;
  padding: 12px 20px;
}

.tips {
  color: #616161;
  border-bottom: #d1d1d1 1px solid;
}
</style>