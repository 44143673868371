<template>
  <div class="nav justify-content-center align-items-center">
    <div class="view-box my-4">
      <div class="tips w-100"> <router-link tag="span" :to="'/news'" >新闻</router-link> > {{ info.title }}</div>
      <h3 class="my-5">{{ info.title }}</h3>
      <div class="w-100 " >
        <div  v-html="info.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "News",
  props: ['id'],
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    let that = this;
    //获取新闻
    this.httpApi.getNewsInfo({id: this.id}).then(response => {
      console.log(response);
      that.info = response.data
    })
  }
}
</script>

<style scoped>

.tips {
  color: #616161;
  border-bottom: #d1d1d1 1px solid;
}

.view-box {
  background-color: #fff;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, .6);
  max-width: 1140px;
  width: 100%;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>