<template>
  <div id="app" class="flex flex-column w-100" style="height: 100vh">
    <router-view ></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  mounted() {
    this.httpApi.getConfig().then((response)=>{
      this.$store.commit("setBaseData",response.data)
    })
    let data =  localStorage.getItem("user")
    console.log("init",data)
    let temp =  JSON.parse(data);
    if (temp.token){
      this.$store.commit('setUser', temp)
    }
  },
}
</script>

<style>
a {
  text-decoration: none;
}
.pointer{
  cursor: pointer;
}
.flex {
  display: flex;
}
.btn,.btn:focus{
  outline: none;
}
#app {
  font-family: Yahei, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.back-color{
  background-color: #1cbc9a;
}
@media screen and (max-width: 768px) {
  .w-xs-100{
    width: 100% !important;
  }
}

</style>
