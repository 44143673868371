<template>
  <div class="nav justify-content-center align-items-center" style="background-color: #f5f5f5">
    <div class="nav py-5 align-items-stretch w-100" style="max-width: 1140px;">
      <div class="c-box mx-lg-2 class-box" :class="{'class-hidden':show_class}">
        <div class="c-title">全部课程</div>
        <div class="" style="max-width: 245px;width: 20vw;min-width: 200px;">
          <el-menu
              :default-active="default_class"
              class="el-menu-vertical-demo w-100"
              ctive-text-color="1cbc9a"
              @select="selectCurr"
              :unique-opened="true">
            <el-submenu :index="item.id.toString()" v-for="(item,index) in classlist" :key="index">
              <template slot="title">{{ item.name }}</template>
              <el-submenu :index="item.id.toString()" v-for="(item,index) in item.childlist" :key="index">
                <template slot="title">{{ item.name }}</template>
                <el-menu-item :index="item.id.toString()" v-for="(item,index) in item.childlist"
                              :key="index">{{ item.name }}
                </el-menu-item>
              </el-submenu>
            </el-submenu>


          </el-menu>
        </div>
      </div>
      <div class="list-box mx-lg-2 p-4">
        <div v-if="!curriculumlist || curriculumlist.length === 0">
          请选择课程
        </div>
        <router-link tag="div" :to="'pdf/' + item.id" v-for="(item,index) in curriculumlist" :key="index" >
          <div class="list-item py-2" v-show="item.type === 2">
            <div>{{ item.unit_name }}</div>
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Data",
  data() {
    return {
      show_class:false,
      classlist: [],
      curriculumlist: [],
      nowClass: 0,
      default_class:0,
      nowCurriculum: 0
    }
  },
  mounted() {
    this.$store.commit('changeNav', 2)
    this.default_class = localStorage.getItem("default_class") ?? 0
    this.nowCurriculum = this.default_class
    this.getList()
  },
  methods: {
    getDefaultID() {
      let classdate = this.classlist[this.nowClass]
      for (const j in classdate.childlist) {
        console.log(classdate.childlist[j]);
        if (classdate.childlist[j].childlist && classdate.childlist[j].childlist.length > 0) {
          return classdate.childlist[j].childlist[0].id
        }
      }
      return 0;
    },
    selectC(info) {
      if (this.nowClass !== info){
        this.nowClass = info
        this.nowCurriculum = this.getDefaultID()
        localStorage.setItem("default_class" , info)
        this.getCurriculumInfo()
    }else{
      this.nowClass = -1
    }
  },
  selectCurr(info) {
    this.nowCurriculum = info
    this.getCurriculumInfo()
  },
  getCurriculumInfo() {
    let that = this
    let id = this.nowCurriculum
    //获取内容
    this.httpApi.getCurriculumList({classid: id}).then(response => {
        console.log(response);
        that.curriculumlist = response.data
      })
      return undefined;
    },
    getList() {
      let that = this
      this.httpApi.getCurriculum().then(response => {
        that.classlist = response.data
        this.nowCurriculum = localStorage.getItem("default_class") ?? 0
        that.getCurriculumInfo()
      })
    }
  }
}
</script>

<style scoped>
.el-menu-item.is-active {
  color: #1cbc9a;
  border-right: #1cbc9a 6px solid;
}

.list-box {
  flex-grow: 1;
  background-color: #fff;
  width: 50%;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, .6);
}

.list-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 1px #d1d1d1 solid;
}

.list-item:hover {
  color: #1cbc9a;
}

.c-list-box {
  max-height: 0;
  transition: .6s ease-out;
  overflow: hidden;
  background-color: #f7f7f7;
}

.c-list-box.open {
  max-height: 600px;
}

.c-list-item.active {
  background-color: #d7fcf5;
  color: #1cbc9a;
  border-right: 4px #1cbc9a solid;
}

.c-list-item {
  border-right: 4px #f7f7f7 solid;
}

.c-box {
  background-color: #FFF;
  box-shadow: 0px 0px 5px -2px rgba(0, 0, 0, .6);
}

.c-name.active {
  fill: #1cbc9a;
  color: #1cbc9a;
}

.c-name.active svg {
  transform: rotate(-180deg);
}

.c-name svg {
  transform: rotate(0deg);
  transition: .6s ease-out;
}

.c-title {
  box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, .2);
  text-align: center;
  padding: 10px 0;
  color: #1cbc9a;
  border-bottom: 4px #1cbc9a solid;
}

.down-ico {
  width: 1.2rem;
  height: 1.2rem;
}
</style>