import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex); //vue的插件机制Vue.use(Vuex); //vue的插件机制

function getLocalToken(){
    let data =  localStorage.getItem("user")
    if (data){
        return JSON.parse(data)
    }else{
        return {token : ""}
    }
}

const store = new Vuex.Store({
    state: { //存放状态
        user: getLocalToken(),
        isLogin: false,
        nav: 0,
        baseData:{}
    },
    mutations: {
        setUser(state, user) {
            // 变更状态
            state.user = {
                nickname: user.nickname,
                avatar: user.avatar,
                token: user.token,
            }
            state.isLogin = true
            //存储缓存
            localStorage.setItem("user", JSON.stringify(state.user));
        },
        logout(state) {
            state.user = {
                token: ""
            }
            state.isLogin = false
        },
        changeNav(state, nav) {
            // 变更状态
            state.nav = nav
        },
        setBaseData(state, baseData){
            state.baseData = baseData
        }
    },
    getters: {
        nav: state => {
            return state.nav
        },
        baseData: state => {
            return state.baseData
        }
    }
})
export default store
