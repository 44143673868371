<template>
  <div>
    <div style="z-index: 100" class="position-fixed w-100 bg-white">
      <HeaderUserInfo/>
      <HeaderNav/>
    </div>
    <HeaderUserInfo/>
    <HeaderNav/>
    <router-view/>
    <FooterUs/>

  </div>

</template>

<script>
import HeaderUserInfo from "@/components/HeaderUserInfo";
import HeaderNav from "@/components/HeaderNav";
import FooterUs from "@/components/FooterUs";

export default {
  name: 'HelloWorld',
  props: {

  },

  components: {FooterUs, HeaderNav, HeaderUserInfo},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
